import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
// import {useHistory} from "react-router";
import {fetchTrx} from "../../../services/trx.service";
import moment from 'moment';
import {API_DSN} from "../../../services/config";
import {useSelector} from "react-redux";

const TrxCaPage = () => {

  const userData = useSelector(state => state.user.user);
  const [trxData, setTrxData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    tx_id: '',
    status: '',
    from_date: moment().format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    type: 'ca',
  });
  const [modalData, setModalData] = useState('');

  const trxPath = '/transactions/export-trx';

  // const history = useHistory();

  useEffect((searchParams = search) => {
    const fetchData = async () => {
      if (searchParams.from_date.length === 10 && searchParams.to_date.length === 10) {
        if (!searchParams.tx_id) {
          // trxHistData(searchParams);
          const result = await trxHistData(searchParams);
          sortingDataDesc(result, 'created_at');
          setTrxData(result);
        }
      }

      if (searchParams.tx_id.length === 24) {
        // trxHistData(searchParams);
        const result = await trxHistData(searchParams);
        sortingDataDesc(result, 'created_at');
        setTrxData(result);
      }
    }

    fetchData()
    // trxHistData(searchParams);
  }, [search]);

  const trxHistData = async (searchParams) => {
    setLoading(true);
    try {
      let res = await fetchTrx(searchParams);
      setLoading(false);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      setLoading(false);
      console.log('err', e.response);
    }

    // fetchTrx(searchParams).then(res => {
    //   setTrxData(res.data.data);
    // }, err => {
    //   alert('Error load data');
    //   console.log('err', err.response);
    // }).finally( () => {
    //   setLoading(false);
    // });
  }

  const sortingDataDesc = (items, key) => {
    items.sort((a, b) => {
      let valA;
      let valB;
      if (typeof a[key] === "string") {
        valA = a[key].toUpperCase(); // ignore upper and lowercase
        valB = b[key].toUpperCase(); // ignore upper and lowercase
      } else {
        valA = a[key]; // ignore upper and lowercase
        valB = b[key]; // ignore upper and lowercase
      }

      if (valA > valB) {
        return -1;
      }
      if (valA < valB) {
        return 1;
      }

      // if equals
      return 0;
    });
  }

  // const updateTrxHandler = (id) => {
  //   console.log('update');
  //   // history.push('/panel/merchants/edit/' + id);
  // }

  const handleChange = (event) => {
    const params = {...search}
    params[event.target.name] = event.target.value;
    setSearch(params);
    // console.log(params)
  }

  const currencyFormat = (num) => {
    if (!num) {
      return;
    }

    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const exportButton = () => {
    const bank = userData.bank_code;
    return (
        <form method="POST" action={`${API_DSN}${trxPath}`}>
          <input type="hidden" name='merchant_id' value={bank} />
          <input type="hidden" name='from_date' value={search.from_date} />
          <input type="hidden" name='to_date' value={search.to_date} />
          <input type="hidden" name='status' value={search.status} />
          <input type="hidden" name='type' value={'ca'} />
          <button type="submit" className="btn btn-primary float-right">Export Data</button>
        </form>
    );
  }

  const handleModalOpen = (e) => {
    const txDetail = e.currentTarget.getAttribute('data-whatever');
    setModalData(txDetail);
  };

  const loopTrx = () => {
    return trxData.map((val, i) => {

      if (val.status === '3') {
        val.status = 'inquiry';
      } else if (val.status === '0') {
        val.status = 'success';
      } else if (val.status === '1') {
        val.status = 'pending';
      } else if (val.status === '2') {
        val.status = 'failed';
      } else if (val.status === '4') {
        val.status = 'success with settlement';
      } else if (val.status === '5') {
        val.status = 'inquiry callback';
      }

      return (
          <tr key={i}>
            <td>{ i+1 }</td>
            <td>{ val.tx_id }</td>
            <td>{ val.ref_id }</td>
            <td>{ val.merchant_id }</td>
            <td>{ val.status }</td>
            <td className='text-right'>{ currencyFormat(val.amount) }</td>
            <td className='text-right'>{ currencyFormat(val.total_admin) }</td>
            <td className='text-right'>{ currencyFormat(val.total_amount) }</td>
            <td className='text-right'>{ currencyFormat(val.deducted_balance) }</td>
            <td>
              {/*<span data-toggle="modal" data-target="#exampleModal" style={{cursor: "pointer", color: "blue"}}*/}
              {/*      data-whatever={val?.tx_detail}>*/}
              {/*  see detail*/}
              {/*</span>*/}

              <span data-toggle="modal" data-target="#exampleModal" style={{cursor: "pointer", color: "blue"}}
                    data-whatever={val?.tx_detail} onClick={handleModalOpen}>
                see detail
              </span>
            </td>
            <td>{val.category_name}</td>
            <td>{val.product_name}</td>
            <td className='text-right'>{currencyFormat(val.first_balance)}</td>
            <td className='text-right'>{currencyFormat(val.last_balance) }</td>
            <td>{ val.updated_at }</td>
          </tr>
      );
    })
  }

  return (
    <div className="row">
      <div className="col-6 mb-2">
        <input
          type="text"
          name="tx_id"
          className="form-control float-right p-1"
          placeholder="Search Tx Id"
          value={search.tx_id}
          onChange={handleChange}
        />
      </div>
      <div className="col-6 mb-2">
        <select
          name="status"
          className="form-control float-right p-1"
          value={search.status}
          onChange={handleChange}
        >
          <option value="">------Select Status------</option>
          <option value="3">Inquiry</option>
          <option value="0">Success</option>
          <option value="1">Pending</option>
          <option value="2">Failed</option>
          <option value="4">Success with settlement</option>
          <option value="4">Inquiry callback</option>
        </select>
      </div>
      <div className="col-6 mb-2">
        <input
          type="text"
          name="from_date"
          className="form-control float-right p-1"
          placeholder="From Date"
          value={search.from_date}
          onChange={handleChange}
        />
      </div>
      <div className="col-6 mb-2">
        <input
          type="text"
          name="to_date"
          className="form-control float-right p-1"
          placeholder="To Date"
          value={search.to_date}
          onChange={handleChange}
        />
      </div>
      <div className="col-12">
        {exportButton()}
      </div>
      <div className="col-12">
        <hr className='hr-user'/>
      </div>
      <div className="col-12">
        <div className="card-body table-responsive p-0">
          <table className="table table-hover text-nowrap">
            <thead>
            <tr>
              <th>ID</th>
              <th>TxId</th>
              <th>Ref ID</th>
              <th>Merchant</th>
              <th>Status</th>
              <th className='text-right'>Amount</th>
              <th className='text-right'>Admin</th>
              <th className='text-right'>Total</th>
              <th className='text-right'>Deducted Bal</th>
              <th>Trx detail</th>
              <th>Category</th>
              <th>Product</th>
              <th className='text-right'>First Balance</th>
              <th className='text-right'>Last Balance</th>
              <th>Created Date</th>
            </tr>
            </thead>
            <tbody>
            {loading && <tr>
              <td colSpan='6'>Load Data</td>
            </tr>}
            {(!loading && trxData.length > 0) && loopTrx()}
            </tbody>
          </table>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {modalData ? modalData : 'Loading...'}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

let pageSet = {
  title: "Transaction History",
  location: '/panel/trx-ca'
}

export default withHeader(pageSet)(TrxCaPage)
