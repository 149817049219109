import React, {useCallback, useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
// import {useHistory} from "react-router";
import {fetchTrxDetail} from "../../../services/trx.service";
import moment from 'moment';
import {useSelector} from "react-redux";
import {useHistory} from "react-router";
import {status} from "../../../assets/static/data";
import {API_DSN} from "../../../services/config";
import Select from "react-select";
import {fetchMerchants} from "../../../services/merchant.service";
import {fetchBanks} from "../../../services/bank.service";

const TrxDetailPage = () => {
  const userData = useSelector(state => state.user.user);
  const [trxData, setTrxData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    filter: '',
    status: '',
    merchant_id: '',
    bank_id: '1',
    from_date: moment().format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
    skip: 1,
    limit: 100,
    type: '',
  });
  const [merchantData, setMerchantData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [merchant] = useState('');
  const [bank] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [timeOutTmp, setTimeOutTmp] = useState(0);

  const history = useHistory();
  const trxPath = '/transactions/auto-recon-export';

  const trxHistData = useCallback(async (searchParams = null) => {
    setLoading(true);

    if (!searchParams) {
      searchParams = {
        from_date: moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
        skip: 1,
        limit: 100,
        bank_id: '1',
      };

      if (userData.role.label === 'ca') {
        searchParams.type = 'ca';
      }
    }

    try {
      const fromToDate = 'fromDate=' + searchParams.from_date + '&toDate=' + searchParams.to_date;
      let res = await fetchTrxDetail(searchParams, fromToDate);
      setLoading(false);
      setTrxData(res.data.data);

      setTotalPage(res.data.count);
      return res.data.data;
    } catch (e) {
      alert('Error load data');
      setLoading(false);
      console.log('err', e.response);
    }
  }, [userData]);

  const banks = useCallback(() => {
    if (userData.role.label === 'ca') {
      // history.push('/panel/dashboard-ca');
      return;
    }

    fetchBanks().then(res => {
      setBankData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }, [userData]);

  const merchants = useCallback(() => {
    if (userData.role.label === 'ca') {
      // history.push('/panel/dashboard-ca');
      return;
    }

    fetchMerchants().then(res => {
      setMerchantData(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }, [userData]);

  useEffect(() => {
    if (userData.role.label !== 'ca') {
      // history.push('/panel/dashboard-ca');
    }
    merchants();
    banks();
    trxHistData(null).then();
  }, [history, userData, trxHistData, banks, merchants]);

  const merchantOptions = merchantData.map(value => {
    return {value: value.kode, label: value.name, name: 'merchant_code', key: value._id}
  });

  const merchantSelectComponent = () => (
      <Select isClearable options={merchantOptions} placeholder='select Merchant' id="merchant_id" name='merchant_code'
              onChange={handleChangeMerchant} required/>
  )

  const handleChangeMerchant = async (event) => {
    const params = {...search}
    if (event) {
      params.merchant_id = event.value;
      setSearch(params);
      await trxHistData(params);
    } else {
      params.merchant_id = '';
      setSearch(params);
      await trxHistData(params);
    }
  }

  // const banks = () => {
  //   if (userData.role.label === 'ca') {
  //     // history.push('/panel/dashboard-ca');
  //     return;
  //   }
  //
  //   fetchBanks().then(res => {
  //     setBankData(res.data.data);
  //   }, err => {
  //     alert('Error load data');
  //     console.log('err', err.response);
  //   });
  // }

  const bankOptions = bankData.map(value => {
    return {value: value.kode, label: value.name, name: 'bank_code', key: value._id}
  });

  const statusSelectComponent = () => (
      <Select isClearable options={statusOptions} placeholder='select Status' id="status" name='status'
              onChange={handleChangeStatus} required/>
  )

  const bankSelectComponent = () => (
      <Select isClearable options={bankOptions} placeholder='select Bank' id="bank_code" name='bank_code'
              onChange={handleChangeBank} required/>
  )

  const handleChangeBank = async (event) => {
    const params = {...search}
    if (event) {
      params.bank_id = event.value;
      setSearch(params);
      await trxHistData(params);
    } else {
      params.bank_id = '1';
      setSearch(params);
      await trxHistData(params);
    }
  }


  const statusOptions = status().map(val => {
    return {
      value: val.id,
      label: val.name
    }
  })

  // const selectStatus = () => {
  //   const options = status().map(val => {
  //     return {
  //       value: val.id,
  //       label: val.name
  //     }
  //   })
  //
  //   return (
  //       <Select
  //           value={options.value}
  //           options={options}
  //           placeholder='select Status'
  //           clearable={true}
  //           onChange={handleChangeStatus}
  //       />
  //   )
  // }

  const handleChange = useCallback(async (event) => {
    setPage(1);
    if (userData.role.label === 'ca') {
      search.type = 'ca';
    }

    if (!event?.target?.value) {
      const params = {...search, filter: ''};
      setSearch(params);
      setPage(1);
      await trxHistData(params);
      return;
    }

    const params = {...search, [event.target.name]: event.target.value};
    setSearch(params);

    if (timeOutTmp) {
      clearTimeout(timeOutTmp);
    }

    const filterTimeout = setTimeout(async () => {
      // console.log('====>', params)
      // const updatedSearch = {...params, filter: event.target.value};
      setSearch(params);
      // console.log('====>', params);
      await trxHistData(params);
    }, 1000);

    setTimeOutTmp(filterTimeout);
  }, [search, timeOutTmp, trxHistData, userData]);

  const handleChangeDate = async (event) => {
    if (userData.role.label === 'ca') {
      search.type = 'ca';
    }

    let params = {...search}
    if (!event?.target?.value) {
      params[event.target.name] = event.target.value;
      // setSearch(params);
      return;
    }

    if (event.target.value.length === 10) {
      params = {...search, [event.target.name]: event.target.value};
      // const updatedSearch = {...params, filter: event.target.value};
      setSearch(params);
      await trxHistData(params);
    } else {
      const params = {...search}
      params[event.target.name] = event.target.value;
      setSearch(params);
    }
  }

  const handleChangeStatus = (event) => {
    const status = event?.value?.toString() || '';
    const params = {...search}
    if (userData.role.label === 'ca') {
      params.type = 'ca';
    }
    if (!status) {
      params.status = status;
      setSearch(params);
      trxHistData(params).then();
      return;
    }

    params.status = status;
    setSearch(params);
    trxHistData(params).then();
  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const loopTrx = () => {
    return trxData.map((val, i) => {

      if (val.status === '3') {
        val.status = 'inquiry';
      } else if (val.status === '0') {
        val.status = 'success';
      } else if (val.status === '1') {
        val.status = 'pending';
      } else if (val.status === '2') {
        val.status = 'failed';
      } else if (val.status === '4') {
        val.status = 'success with settlement';
      } else if (val.status === '5') {
        val.status = 'inquiry callback';
      }

      if (page === 1) {
        val.no = i + 1;
      } else {
        const limit = search.limit * (page - 1);
        val.no = i + 1 + limit;
      }

      if (!val.ref_id) {
        val.ref_id = val.refId;
      }

      return (
          <tr key={i}>
            <td>{val.no}</td>
            <td>{val.tx_id}</td>
            <td>{val.ref_id ? val.ref_id : val.refId}</td>
            <td>{val.bill_id}</td>
            <td>{val.va_bank_cd}</td>
            <td>{val.va_no}</td>
            <td>{val.merchant_id}</td>
            <td>{val.bankCode}</td>
            <td>{val.bank_id}</td>
            <td className='text-right'>{currencyFormat(val.amount)}</td>
            <td>{val.period}</td>
            <td>{val.product_name}</td>
            <td>{val.updated_at}</td>
          </tr>
      );
    })
  }

  const exportButton = () => {
    let merchant_id = search.merchant_id || '';
    return (
        <form method="POST" action={`${API_DSN}${trxPath}`}>
          <input type="hidden" name='merchant_id' value={merchant_id}/>
          <input type="hidden" name='bank_id' value={search.bank_id}/>
          <input type="hidden" name='fromDate' value={search.from_date}/>
          <input type="hidden" name='toDate' value={search.to_date}/>
          <input type="hidden" name='status' value={search.status}/>
          <button type="submit" className="btn btn-primary float-right">Export Data</button>
        </form>
    );
  }

  const handleChangePage = async (currentPage = 1) => {
    let searchProd2 = {};
    setPage(currentPage);
    search.skip = currentPage;

    if (merchant) {
      search.merchant_id = merchant;
    }
    if (bank) {
      search.bank_id = bank;
    }

    if (userData.role.label === 'ca') {
      search.type = 'ca';
    }

    setLoading(true);
    try {
      const fromToDate = 'fromDate=' + search.from_date + '&toDate=' + search.to_date;
      searchProd2 = await fetchTrxDetail(search, fromToDate);
      setPage(currentPage);
      search.skip = page;
      setLoading(false);
    } catch (e) {
      alert('load data error');
    }

    setTrxData(searchProd2.data.data);
  }

  const loopPagination = () => {
    const pagination = Math.ceil(totalPage / search.limit);
    const arr = [];
    let arr2 = [];
    for (let i = 0; i < pagination; i++) {
      arr.push(i);
    }

    arr.forEach((val, i) => {
      if ((page + 5) - (i + 1) >= 0) {
        if (page < 7) {
          arr2.push(i);
        } else if ((page - 5) - (i + 1) <= 0) {
          arr2.push(i);
        }
      }
    });

    return arr2.map((val, i) => {
      return (
          <li className={page === val + 1 ? 'page-item active' : 'page-item'} key={'pagination_' + i}>
            <span className="page-link" onClick={() => handleChangePage(val + 1)}>{val + 1}</span>
          </li>
      );
    });
  }

  const loopPagination2 = () => {
    if (page > 6) {
      return (
          <li className="page-item" key={'pagination_2_xyz'}>
            <span className="page-link">...</span>
          </li>
      );
    }
  }

  const loopPagination3 = () => {
    if (page > 6) {
      return (
          <li className="page-item" key={'pagination_3_xyz'}>
            <span className="page-link">...</span>
          </li>
      );
    }
  }

  return (
      <div className="row">
        <div className="col-6 mb-2">
          <input
              type="text"
              name="filter"
              className="form-control float-right p-1"
              placeholder="Search Tx Id"
              value={search.filter}
              onChange={handleChange}
          />
        </div>
        <div className="col-6 mb-2">
          {statusSelectComponent()}
        </div>
        <div className="col-6 mb-2">
          {merchantData.length > 0 && merchantSelectComponent()}
        </div>
        <div className="col-6 mb-2">
          {bankData.length > 0 && bankSelectComponent()}
        </div>
        <div className="col-6 mb-2">
          <input
              type="text"
              name="from_date"
              className="form-control float-right p-1"
              placeholder="From Date"
              value={search.from_date}
              onChange={handleChangeDate}
          />
        </div>
        <div className="col-6 mb-2">
          <input
              type="text"
              name="to_date"
              className="form-control float-right p-1"
              placeholder="To Date"
              value={search.to_date}
              onChange={handleChangeDate}
          />
        </div>
        <div className="col-12">
          {exportButton()}
        </div>
        <div className="col-12">
          <hr className='hr-user'/>
        </div>
        <div className="col-12">
          <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
              <thead>
              <tr>
                <th>ID</th>
                <th>TxId</th>
                <th>Ref ID</th>
                <th>Bill ID</th>
                <th>Bank</th>
                <th>Va</th>
                <th>Merchant</th>
                <th>Bank Code</th>
                <th>Biller</th>
                <th className='text-right'>Amount</th>
                <th>Period</th>
                <th>Product</th>
                <th>Created Date</th>
              </tr>
              </thead>
              <tbody>
              {loading && <tr>
                <td colSpan='6'>Load Data</td>
              </tr>}
              {(!loading && trxData.length > 0) && loopTrx()}
              </tbody>
            </table>

            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <span className="page-link"
                        onClick={() => page === 1 ? '' : handleChangePage(page - 1)}>Previous</span>
                </li>
                {loopPagination3()}
                {(!loading && trxData.length > 0) && loopPagination()}
                {loopPagination2()}
                <li className="page-item">
                  <span className="page-link" onClick={() => handleChangePage(page + 1)}>Next</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                ...
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

let pageSet = {
  title: "Transaction History Detail",
  location: '/panel/trx'
}

export default withHeader(pageSet)(TrxDetailPage)
