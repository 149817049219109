import {Get, Post, Put} from "./config";

// Bank Endpoint
// export const storeMerchant = (params) => Post('merchants', params);
export const updateDeposit = (id, params) => Put('transactions', id, params);
export const fetchTrx = (params) => Post( 'transactions', params);
export const fetchTrxDetail = (params, fromToDate) => Post( 'transactions/auto-recon?' + fromToDate, params);
export const fetchTrxCount = (params) => Post( 'transactions/count-trx', params);
export const storeDeposit = (params) => Post( 'transactions/deposit', params);
export const fetchTrxById = (id) => Get( 'transactions/' + id);
// export const deleteMerchantById = (id, params) => Delete( 'merchants/' + id, params);
