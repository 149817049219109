import React, {useEffect, useState} from 'react';
import withHeader from '../../hoc/withHeader';
import {useHistory} from "react-router";
import {useSelector} from "react-redux";
import {API_DSN} from "../../../services/config";
import Select from "react-select";
import {fetchBanks} from "../../../services/bank.service";
import moment from "moment/moment";
import {fetchSyncRecons} from "../../../services/recon.service";
import {seacrchProductByParam} from "../../../services/products.service";

const SyncPage = () => {
  const userData = useSelector(state => state.user.user);
  const [banks, setBanks] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [bank, setBank] = useState('');
  const [timeOutTmp, setTimeOutTmp] = useState(0);
  const [fromDate, setFromDate] = useState({
    from_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    to_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
  });
  const [reconData, setReconData] = useState([]);
  const [reconDataTmp, setReconDataTmp] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (userData.role.label === 'admin') {
      setIsAdmin(true);
    }
    productData();
    bankData();
  }, [history, userData, isAdmin]);

  const getReconData = async (bankCode, from_date = null) => {
    setLoading(true);

    let params = fromDate;
    if (from_date) {
      params = {
        from_date: from_date,
        to_date: from_date,
      };
      setFromDate(params)
    } else if (fromDate.from_date.length !== 10) {
      return [];
    }

    let res = [];
    try {
      res = await fetchSyncRecons(bankCode, params);
    } catch (e) {
      alert('Error load data');
      console.log('err', e);
    }

    setLoading(false);
    return res.data.data;
  }

  const currencyFormat = (num) => {
    if (!num) {
      return '0.00';
    }
    if (typeof num === "string") {
      num = Number(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const productData = () => {
    const params = {
      category_id: 'PDAM001',
    }
    seacrchProductByParam(params).then(res => {
      setProducts(res.data.data);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const bankData = () => {

    fetchBanks().then(res => {
      const filteredBanks = res.data.data.filter(bank => bank.kode === 'PDAMTKR001' || bank.kode === 'PDAMTB001');
      setBanks(filteredBanks);
    }, err => {
      alert('Error load data');
      console.log('err', err.response);
    });
  }

  const handleChange = (event) => {
    const val = event.target.value;
    setFromDate({
      from_date: val,
      to_date: val
    });

    let searchRes = reconDataTmp;

    if (!event.target.value) {
      return;
    }

    clearTimeout(timeOutTmp)
    if (!event) return "";

    if (!bank) {
      return;
    }

    if (event.target.value.length !== 10) {
      return;
    }

    const filterTimeout = setTimeout(async () => {
      console.log('====>', val)

      searchRes = {};
      if (val.length !== 10) {
        return;
      }
      searchRes = await getReconData(bank, val);

      const searchRes2 = searchRes.filter(valFilter => {
        return true;
      });

      setReconData(searchRes2 || []);

    }, 1000);

    setTimeOutTmp(filterTimeout);
  }

  const handleChangeBank = async (event, currentPage = 1) => {
    let searchProd2 = {};

    if (fromDate.from_date.length !== 10) {
      return;
    }

    if (event === null) {
      setBank({});
      searchProd2 = setReconData([]);
    } else {
      const bank = event.value;
      setBank(bank)
      searchProd2 = await getReconData(bank);
    }

    setReconData(searchProd2);
    setReconDataTmp(searchProd2);
  }

  const BanksOptions = banks.map(value => {
    return { value: value.kode, label: value.name, name: 'bank_id', key: value._id }
  })
  const BanksSelectComponent = () => (
      <Select isClearable options={BanksOptions} placeholder='select Bank' id="bank_id" name='bank_id' onChange={handleChangeBank} />
  )

  const ProductsOptions = products.map(value => {
    return { value: value.kode, label: value.name, name: 'product_id', key: value._id }
  })
  const ProductSelectComponent = () => (
      <Select isClearable options={ProductsOptions} placeholder='select PDAM' id="product_id" name='product_id' onChange={handleChangeBank} />
  )

  const exportButton = () => {
    const trxPath = '/recon/export-excel';
    return (
        <form method="POST" action={`${API_DSN}${trxPath}`}>
          <input type="hidden" name='from_date' value={fromDate.from_date} />
          <input type="hidden" name='to_date' value={fromDate.to_date} />
          <input type="hidden" name='bankCode' value={bank} />
          <button type="submit" className="btn btn-primary btn-block">Sync Data</button>
        </form>
    );
  }
  
  const loopRecon = () => {
    return reconData.map((val, i) => {
      return (
          <tr key={val.printno + '_' + i}>
            <td>{i + 1}</td>
            <td>{bank}</td>
            <td>{val.billing_id}</td>
            <td>{val.period}</td>
            <td>{val.ref_id}</td>
            <td>{fromDate.from_date}</td>
            <td>{val.merchant}</td>
            <td>{currencyFormat(val?.amount || 0)}</td>
          </tr>
      );
    })
  }

  return (
    <div className="row">
      <div className="col-sm-4 mb-3">
        {ProductSelectComponent()}
      </div>
      <div className="col-sm-4 mb-3">
        {BanksSelectComponent()}
      </div>
      <div className="col-sm-4 mb-3">
        <input
          type="text"
          name="table_search"
          className="form-control p-1"
          onChange={handleChange}
          value={fromDate.from_date}
          placeholder="Select Date" />
      </div>

      <div className="col-sm-4">
        {exportButton()}
      </div>
      <div className="col-12">
        <hr className='hr-user' />
      </div>
      <div className="col-12">
        <div className="card-body table-responsive p-0">
          <table className="table text-nowrap table-striped table-sm">
            <thead>
            <tr>
              <th>No</th>
              <th>PDAM</th>
              <th>Bill ID</th>
              <th>Period</th>
              <th>Ref ID</th>
              <th>Date</th>
              <th>Locket</th>
              <th>Total</th>
            </tr>
            </thead>
            <tbody>
              {loading && <tr><td colSpan='6'>Load Data</td></tr>}
              {(!loading && reconData.length > 0) && loopRecon()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

let pageSet = {
  title: "Sync",
  location: '/panel/sync'
}

export default withHeader(pageSet)(SyncPage)
